.webPageCardGrid {
    height: 100%;
    width: calc(400px + 48px);
    margin: 12px;
}

.webPageCardGrid img {
    height: 100%;
    width: 100%;
}

.webPageCardGrid .ant-divider {
    margin-bottom: 6px;
}
.webPageCardGrid .description {
    text-align: center;
}

@media (max-width: 576px) {
    .webPageCardGrid {
        height: 100%;
        width: calc(100% - 24px);
        margin: 12px 0;
        padding: 12px;
    }

    .webPageCardGrid img {
        height: 100%;
        width: 100%;
    }
}
