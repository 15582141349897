.banner {
    /* background-image: url('/bg.jpg'); */
    /* background-size: cover; */
    background: url('/images/home/bg.jpg') scroll no-repeat 50% 0;
    height: 844px;
    width: 100%;
    max-width: 1600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-right: 8em;
}

.banner > p {
    color: #fff;
    font-size: xx-large;
    text-transform: uppercase;
}

@media (max-width: 1200px) {
    .banner {
        background-image: url('/images/home/bg.jpg');
        background-size: cover;
        height: 600px;
        padding-right: 4em;
    }

    /* .banner > p {
        color: #000000;
        font-size: medium;
        text-transform: uppercase;
        font-weight: bold;
        padding: 10px;
    } */
}

@media (max-width: 992px) {
    .banner {
        background-image: url('/images/home/bg.jpg');
        background-size: cover;
        height: 400px;
        padding-right: 4em;
    }
}

@media (max-width: 576px) {
    .banner {
        background-image: url('/images/home/bg_mobile.jpg');
        background-size: cover;
        height: 200px;
        padding-right: 1em;
    }

    .banner > p {
        font-size: medium;
        padding: 10px;
    }
}
