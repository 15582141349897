.ant-layout-header {
    background: #000000;
    height: 48px;
    line-height: 48px;
    text-align: center;
    padding: 0;
}

.ant-menu.menu.ant-menu-dark.ant-menu-root.ant-menu-horizontal {
    background: #000000;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
    background: #000000;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected a {
    color: #1890ff;
}
