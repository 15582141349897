.projectIcon {
    width: 88px;
}

.ant-card-body {
    padding: 12px;
}

.ant-row .ant-row-middle {
    height: 100%;
}
